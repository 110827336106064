import React from "react";
import { rhythm } from "../utils/typography";
import Avatar from "./avatar";
import { Flex, Box, Text } from "rebass";

const Bio = () => (
  <Flex
    style={{
      display: `flex`,
      alignContent: "center",
      flexWrap: "wrap",
    }}
  >
    <Box>
      <Avatar />
    </Box>
    <Box
      style={{
        maxWidth: "500px",
      }}
    >
      <Text
        as="h1"
        color="white"
        mt="0"
        mb={rhythm(1.5)}
        fontFamily="handwriting"
        css={`
          transform: rotate(-5deg);
        `}
      >
        Hi, I'm Karsten!!!
      </Text>
      <Text>
        I live in Munich and I work at{" "}
        <a
          href="https://www.internations.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          InterNations
        </a>{" "}
      </Text>
      <Text>
        I like working with <em>React</em> and <em>Symfony</em>, I like to
        travel, call myself a sneakerhead, play lots of <em>Destiny</em>, and
        sometimes take lots of photos.
      </Text>
      <Text>
        If you want to, you can follow me on
        {` `}
        <a
          href="https://twitter.com/kasn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        ,{` `}
        <a
          href="https://unsplash.com/@kasn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unsplash
        </a>
        , and{" "}
        <a
          href="https://github.com/kasn"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
      </Text>
    </Box>
  </Flex>
);

export default Bio;
