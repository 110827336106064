import React from "react";
import { Link } from "gatsby";
import { rhythm } from "../utils/typography";
import Bio from "./bio";
import Avatar from "./avatar";
import { ThemeProvider } from "styled-components";
import { Box, Text } from "rebass";
import theme from "../theme";

// coolors.co/070600-d65947-f7f7ff-23b5d3-279af1

const SmallHeader = () => (
  <Text as="h3" mt={0} fontFamily="sans">
    <Link
      style={{
        boxShadow: `none`,
        textDecoration: `none`,
        color: `#F7F7FF`,
        display: "flex",
        alignContent: "center",
      }}
      to={`/`}
    >
      <Avatar width="50px" height="50px" />
      <Text
        fontFamily="handwriting"
        mt={rhythm(0.5)}
        css={`
          transform: rotate(-5deg);
        `}
      >
        Hi, I'm Karsten
      </Text>
    </Link>
  </Text>
);

function Layout({ location, children }) {
  const rootPath = `${__PATH_PREFIX__}/`;

  return (
    <ThemeProvider theme={theme}>
      <Box bg="white">
        <Box bg="blue">
          <header
            style={{
              color: `#F7F7FF`,
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(40),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
            {location.pathname === rootPath ? <Bio /> : <SmallHeader />}
          </header>
        </Box>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(40),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <main>{children}</main>
        </div>
        <Box bg="lightblue" color="white">
          <Box
            mx="auto"
            style={{
              maxWidth: rhythm(40),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
            {location.pathname !== rootPath && <Bio />}
            <footer>
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a>
              <br />
              <br />
              <a
                rel="license"
                href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              >
                <img
                  alt="Creative Commons License"
                  src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
                />
              </a>
              <br />
              This work is licensed under a{" "}
              <a
                rel="license"
                href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              >
                Creative Commons Attribution-NonCommercial-ShareAlike 4.0
                International License
              </a>
            </footer>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
