const theme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    white: "#F7F7FF",
    lightgray: "#f6f6ff",
    blue: "#279AF1",
    lightblue: "#23B5D3",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: "Montserrat, sans-serif",
    mono: "Menlo, monospace",
    handwriting: `Rock Salt, sans-serif`,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
};

export default theme;
