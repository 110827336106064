import React from "react";
import Avatar from "avataaars";

const A = ({ width = "264px", height = "280px" }) => (
  <Avatar
    style={{ width, height }}
    avatarStyle="Circle"
    topType="ShortHairShaggyMullet"
    hairColor="Blonde"
    facialHairType="BeardMajestic"
    facialHairColor="Blonde"
    clotheType="ShirtCrewNeck"
    clotheColor="̆Black"
    eyeType="Happy"
    eyebrowType="RaisedExcited"
    mouthType="Smile"
    skinColor="Light"
  />
);

export default A;
